import React from 'react';

const MissionAndVision = () => {
  return (
    <section id="Mission-and-Vision" className="Mission-and-Vision">
      <div className="container">
      <div className="section-title">
            <h2>Mission and Vision</h2>
            <p></p>
          </div>
        <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="card">
              <div className="card-body">
                <div className="section-title">
                  <img src="assets/img/m.png" width="13%" alt="Mission" />
                  <h2>Mission</h2>
                </div>
                <p className='text-center d-flex justify-content-center align-items-center'>
              <strong>
              Promoting wealthier society by creation of disciplined thrift mindset and improve living standards 
                </strong> 
                </p>

              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="card">
              <div className="card-body">
                <div className="section-title">
                  <img src="assets/img/v.png" width="13%" alt="Vision" />
                  <h2>Vision</h2>
                </div>
                <p className='text-center d-flex justify-content-center align-items-center'>
                <strong>
                Creation of moral, social and financial wealth to evolve a happy and healthy life index
                </strong>
                </p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>
  );
};

export default MissionAndVision;
