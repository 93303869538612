import React, { useState, useEffect } from 'react';

const IndexCards = () => {
  const [indicesData, setIndicesData] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      // Fetch data from the API endpoint
      fetch('https://marketapi.intoday.in/widget/tvtnindices/view?exchange=indices')
        .then(response => response.json())
        .then(data => {
          // Filter data for BSE and NSE indices
          const bseIndex = data.data.find(index => index.indexname === 'Sensex');
          const nseIndex = data.data.find(index => index.indexname === 'NIFTY 50');
          setIndicesData([bseIndex, nseIndex]);
        })
        .catch(error => console.error('Error fetching data:', error));
    };

    // Fetch data initially
    fetchData();

    // Refresh data every 0.5 seconds
    const intervalId = setInterval(fetchData, 10000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Function to format the date
  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: '2-digit', hour: '2-digit', minute: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options).replace(',', ''); // Remove comma after day
  };

  // Function to determine color based on value
  const getColor = (value) => {
    return value >= 0 ? 'green' : 'red';
  };

  return (
    <section id="NSE/BSE" className="NSE/BSE">
      <div className='container'>
        <div className="row">
          {indicesData.map(index => (
            <div key={index.indexname} className="col-sm-6">
              <div className="card">
                <div className="card-body">
                  <div className='row d-flex'>
                    <div className='col-lg-6 text-center'>
                      {index.indexname === 'Sensex' && <img src='assets/img/BSE1.png' width={160} alt="BSE" className='mt-1' />}
                      {index.indexname === 'NIFTY 50' && <img src='assets/img/NSE1.png' width={200} alt="NSE" className='mt-4' />}
                    </div>
                    <div className='col-lg-6 mt-2 text-center'>
                      <p className="card-text"><strong>{index.close}</strong> </p>
                      <p className="card-text"><strong><span style={{ color: getColor(parseFloat(index.net_change)) }}>{parseFloat(index.net_change).toFixed(2)}</span> <span style={{ color: getColor(parseFloat(index.per_change)) }}>{parseFloat(index.per_change).toFixed(2)}%</span></strong> </p>
                      <p className="card-text"><strong> </strong></p>
                      <p className="card-text"><strong>{formatDate(index.tr_date)}</strong> </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default IndexCards;
