import React from 'react';

function Hero() {
  return (
    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">

        {/* <div className="carousel-item">
          <section id="hero" className="d-flex align-items-center" style={{ backgroundImage: "url('assets/img/s1.png')", backgroundSize: "cover", backgroundPosition: "center" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 order-1 order-lg-2 hero-img">
               
                </div>
              </div>
            </div>
          </section>
        </div> */}

        <div className="carousel-item active">
          <section id="hero" className="d-flex align-items-center" style={{ backgroundImage: "url('assets/img/s1.png')", backgroundSize: "cover", backgroundPosition: "center" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 order-1 order-lg-2 hero-img">
                  {/* <img src="assets/img/s2.jpg" className="img-fluid" alt="" /> */}
                </div>
              </div>
            </div>
          </section>
        </div>

      </div>
      {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button> */}
    </div>
  );
}

export default Hero;
