import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import numeral from 'numeral';


const priceInWords = (price) => {
  // Function to convert numbers to words (price_in_words equivalent)
  // Implementation here...
  const sglDigit = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"],
    dblDigit = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"],
    tensPlace = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"],
    handleTens = (dgt, prevDgt) => 0 === dgt ? "" : ` ${1 === dgt ? dblDigit[prevDgt] : tensPlace[dgt]}`,
    handleUtlc = (dgt, nxtDgt, denom) => `${0 !== dgt && 1 !== nxtDgt ? ` ${sglDigit[dgt]}` : ""}${0 !== nxtDgt || dgt > 0 ? ` ${denom}` : ""}`;

  let str = "",
    digitIdx = 0,
    digit = 0,
    nxtDigit = 0,
    words = [];
  if ((price += "", isNaN(parseInt(price)))) str = "";
  else if (parseInt(price) > 0 && price.length <= 10) {
    for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--)
      switch ((digit = price[digitIdx] - 0), (nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0), price.length - digitIdx - 1) {
        case 0:
          words.push(handleUtlc(digit, nxtDigit, ""));
          break;
        case 1:
          words.push(handleTens(digit, price[digitIdx + 1]));
          break;
        case 2:
          words.push(0 !== digit ? ` ${sglDigit[digit]} Hundred${0 !== price[digitIdx + 1] && 0 !== price[digitIdx + 2] ? " and" : ""}` : "");
          break;
        case 3:
          words.push(handleUtlc(digit, nxtDigit, "Thousand"));
          break;
        case 4:
          words.push(handleTens(digit, price[digitIdx + 1]));
          break;
        case 5:
          words.push(handleUtlc(digit, nxtDigit, "Lakh"));
          break;
        case 6:
          words.push(handleTens(digit, price[digitIdx + 1]));
          break;
        case 7:
          words.push(handleUtlc(digit, nxtDigit, "Crore"));
          break;
        case 8:
          words.push(handleTens(digit, price[digitIdx + 1]));
          break;
        case 9:
          words.push(0 !== digit ? ` ${sglDigit[digit]} Hundred${0 !== price[digitIdx + 1] || 0 !== price[digitIdx + 2] ? " and" : " Crore"}` : "");
      }
    str = words.reverse().join("");
  } else str = "";
  return `${str} Rupees Only`;
};

const formatNumber = (value) => {
  return numeral(value).format('0,0.00');
};

const EMIcal = () => {
  const [loanAmount, setLoanAmount] = useState(Cookies.get('loan_amount') || 100000);
  const [loanRangeStep, setLoanRangeStep] = useState(Cookies.get('loan_range_step') || 100000);
  const [min, setMin] = useState(100000);
  const [max, setMax] = useState(1000000);
  const [tenureType, setTenureType] = useState(Cookies.get('tenure_type') || 2);
  const [maxTenure, setMaxTenure] = useState(tenureType === 1 ? 360 : tenureType === 2 ? 30 : 90);
  const [interestType, setInterestType] = useState(Cookies.get('interest_type') || 1);
  const [interest, setInterest] = useState(Cookies.get('interest') || 12);
  const [tenure, setTenure] = useState(Cookies.get('tenure') || 1);
  const [emi, setEmi] = useState(undefined);
  const [totalInterest, setTotalInterest] = useState(undefined);
  const [tenureInMonths, setTenureInMonths] = useState(undefined);

  useEffect(() => {
    calculateEMI();
  }, [loanAmount, tenure, interest, tenureType, interestType]);

  const calculateEMI = () => {
    let term = tenureType === 2 ? tenure * 12 : tenure;
    setTenureInMonths(term);
    let interestValue = interestType === 1 ? interest / (tenureType === 3 ? 36500 : 1200) : interest;
    if (interestType === 1) {
      let top = Math.pow(1 + interestValue, term);
      let bottom = top - 1;
      let ratio = top / bottom;
      let principal = (loanAmount / term).toFixed(2);
      let emiValue = (loanAmount * interestValue * ratio).toFixed(2);
      setEmi(emiValue);
      setTotalInterest(((emiValue - principal) * term).toFixed(2));
    } else {
      let totalInterestValue = (loanAmount * interest / (tenureType === 3 ? 36500 : 1200) * term).toFixed(2);
      setTotalInterest(totalInterestValue);
      setEmi(((parseFloat(loanAmount) + parseFloat(totalInterestValue)) / term).toFixed(2));
    }
    Cookies.set('loan_amount', loanAmount);
    Cookies.set('tenure', tenure);
    Cookies.set('interest', interest);
    Cookies.set('tenure_type', tenureType);
    Cookies.set('interest_type', interestType);
    Cookies.set('loan_range_step', loanRangeStep);
  };

  return (
    <div className="bg-dark text-light min-vh-100" id="emicalc" style={{borderRadius:'20px'}}>
      <div className="container py-4">
        <div className="text-center mb-4">
          <h1 className="font-weight-bold">{tenureType !== 3 ? 'EMI' : 'EDI'}</h1>
          <h2 className="text-success">
            ₹ {formatNumber(emi)} {tenureType !== 3 ? 'per month' : 'per day'}
          </h2>
          <p>
            for {tenureInMonths} {tenureType !== 3 ? (tenureInMonths === 1 ? 'month' : 'months') : (tenureInMonths === 1 ? 'day' : 'days')}
          </p>
        </div>
        <div className="text-center">
          <h1 className="font-weight-bold">Total Interest that you'll pay</h1>
          <h2 className="text-success">₹ {formatNumber(totalInterest)}</h2>
        </div>
        <div className="my-4">
          <div className="card bg-light mb-4">
            <div className="card-body text-center">
              <h5 className="card-title text-uppercase">Loan Amount</h5>
              <p className="text-muted">Click to Enter</p>
              <input
                type="number"
                className="form-control text-center font-weight-bold"
                value={loanAmount}
                onChange={(e) => setLoanAmount(parseInt(e.target.value))}
              />
              <p className="text-muted">{priceInWords(loanAmount)}</p>
              <input
                type="range"
                className="custom-range"
                min={min}
                max={max}
                step={loanRangeStep}
                value={loanAmount}
                onChange={(e) => setLoanAmount(parseInt(e.target.value))}
              />
              <div className="d-flex justify-content-between mt-4">
                <button className={`btn ${loanRangeStep === 1000 ? 'btn-dark' : 'btn-light'}`} onClick={() => { setLoanRangeStep(1000); setLoanAmount(1000); setMin(1000); setMax(100000); }}>One Thousand</button>
                <button className={`btn ${loanRangeStep === 10000 ? 'btn-dark' : 'btn-light'}`} onClick={() => { setLoanRangeStep(10000); setLoanAmount(10000); setMin(10000); setMax(1000000); }}>Ten Thousand</button>
                <button className={`btn ${loanRangeStep === 100000 ? 'btn-dark' : 'btn-light'}`} onClick={() => { setLoanRangeStep(100000); setLoanAmount(100000); setMin(100000); setMax(1000000); }}>One Lakh</button>
                <button className={`btn ${loanRangeStep === 1000000 ? 'btn-dark' : 'btn-light'}`} onClick={() => { setLoanRangeStep(1000000); setLoanAmount(1000000); setMin(1000000); setMax(100000000); }}>Ten Lakh</button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-4">
              <div className="card bg-light">
                <div className="card-body text-center">
                  <h5 className="card-title text-uppercase">Tenure</h5>
                  <input
                    type="number"
                    className="form-control text-center font-weight-bold"
                    value={tenure}
                    onChange={(e) => setTenure(parseInt(e.target.value))}
                  />
                  <input
                    type="range"
                    className="custom-range"
                    min="1"
                    max={maxTenure}
                    value={tenure}
                    onChange={(e) => setTenure(parseInt(e.target.value))}
                  />
                  <div className="d-flex justify-content-between mt-4">
                    <button className={`btn ${tenureType === 3 ? 'btn-dark' : 'btn-light'}`} onClick={() => { setTenureType(3); setTenure(1); setMaxTenure(90); }}>D</button>
                    <button className={`btn ${tenureType === 1 ? 'btn-dark' : 'btn-light'}`} onClick={() => { setTenureType(1); setTenure(1); setMaxTenure(360); }}>M</button>
                    <button className={`btn ${tenureType === 2 ? 'btn-dark' : 'btn-light'}`} onClick={() => { setTenureType(2); setTenure(1); setMaxTenure(30); }}>Y</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="card bg-light">
                <div className="card-body text-center">
                  <h5 className="card-title text-uppercase">Interest</h5>
                  <div className="d-flex justify-content-center align-items-center">
                    <input
                      type="number"
                      step="0.01"
                      className="form-control text-center font-weight-bold"
                      value={interest}
                      onChange={(e) => setInterest(parseFloat(e.target.value))}
                    />
                    <span className="ml-2">%</span>
                  </div>
                  <input
                    type="range"
                    className="custom-range"
                    min="1"
                    max="365"
                    value={interest}
                    onChange={(e) => setInterest(parseFloat(e.target.value))}
                  />
                  <div className="d-flex justify-content-between mt-4">
                    <button className={`btn ${interestType === 1 ? 'btn-dark' : 'btn-light'}`} onClick={() => setInterestType(1)}>Reducing</button>
                    <button className={`btn ${interestType === 2 ? 'btn-dark' : 'btn-light'}`} onClick={() => setInterestType(2)}>Flat</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default EMIcal;
