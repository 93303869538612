import React, { useEffect, useState } from 'react';

function Header() {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementById('header');
      const navbar = document.getElementById('navbar');
      const navLinks = navbar.querySelectorAll('.nav-link');
      if (window.scrollY > 0) {
        header.style.backgroundColor = '#fff';
        header.style.boxShadow = '0px 2px 15px rgba(0, 0, 0, 0.05)';
        navLinks.forEach(link => {
          link.style.color = '#000';
        });
      } else {
        header.style.backgroundColor = 'transparent';
        header.style.boxShadow = '0px 0px 0px rgba(0, 0, 0, 0.00)';
        navLinks.forEach(link => {
          link.style.color = 'gray';
        });
      }
    };

    const handleLinkHover = () => {
      const navLinks = document.querySelectorAll('.nav-link');
      navLinks.forEach(link => {
        link.addEventListener('mouseenter', () => {
          link.style.color = '#ffd643'; // Change color on hover
        });
        link.addEventListener('mouseleave', () => {
          if (window.scrollY === 0) {
            link.style.color = '#fff'; // Revert to default color if not scrolled
          } else {
            link.style.color = '#000'; // Revert to scrolled color if scrolled
          }
        });
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleLinkHover();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };


  const handleNavLinkClick = () => {
    if (isMobileNavOpen) {
      setIsMobileNavOpen(false);
    }
  };



  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };


  return (
    <div>
      <header id="header" className="fixed-top" style={{ backgroundColor: 'transparent' }}>
        <div className="container d-flex align-items-center justify-content-between">
          <a href="index.html" className="logo">
            <img src="assets/img/logo.jpg" width={100} style={{borderRadius:"5px"}} alt="" className="img-fluid" />
          </a>
          {/* Uncomment below if you prefer to use text as a logo */}
          {/* <h1 class="logo"><a href="index.html">Butterfly</a></h1> */}
          <nav id="navbar" className={`navbar ${isMobileNavOpen ? 'navbar-mobile' : ''}`}>
            <ul>
              <li>
                <a className="nav-link scrollto " href="#hero"   onClick={handleNavLinkClick}>
                  Home
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#about"  onClick={handleNavLinkClick} >
                  About
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#Mission-and-Vision"  onClick={handleNavLinkClick}>
                  Mission & Vision
                </a>
              </li>
              <li className="dropdown">
                  <a  className="nav-link" href="#" onClick={toggleDropdown}>
                    <span>Resource Links</span><i class="bi bi-caret-down-fill" />
                  </a>
                  <ul className={`dropdown ${isDropdownOpen ? "dropdown-active" : ""}`}>
                    <li>
                      <a href="https://www.rbi.org.in/" target='_blank'>RBI</a>
                    </li>
                    <li>
                      <a href="https://www.sebi.gov.in/" target='_blank'>SEBI</a>
                    </li>
                  </ul>
                </li>

              <li>
                <a className="nav-link scrollto" href="#services"  onClick={handleNavLinkClick}>
                  Services
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#contact"  onClick={handleNavLinkClick}>
                  Contact
                </a>
              </li>
            </ul>
            <i className={`bi ${isMobileNavOpen ? 'bi-x' : 'bi-list'} mobile-nav-toggle`} style={{ color: "black" }} onClick={toggleMobileNav} />
          </nav>
          {/* .navbar */}
        </div>
      </header>
    </div>
  );
}

export default Header;
