import React from 'react'

function About() {
  return (
    <div>
           <section id="about" className="about">
      <div className="container">
      <div className="section-title">
            <h2>About us</h2>
            <p></p>
          </div>
        <div className="row">
          <div className="col-xl-5 col-lg-6 d-flex justify-content-center video-box align-items-stretch position-relative">
           
          </div>
          <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
            <h3>Who we are</h3>
            <p style={{textAlign:"justify"}}>
              
Our journey began in Visakhapatnam, the City of Destiny, in Andhra Pradesh as a wealth management company. 
We are a value driven company and our core values are based on four simple principles awareness, trust, respect and service.
 Providing bespoke financial freedom and security is what makes the difference as it enhances customer experience. 
 Initially we started with the life/term and health insurance domain and soon ventured into investments, all kinds of other insurances and services.
            </p>
            <div className='d-flex'>
            <div className="icon-box">
            <img src='assets/img/s.png' width={50} />
            <p><strong>Protection</strong></p>
              <h4 className="title">
                <a href=""></a>
              </h4>
             
            </div>
            <div className="icon-box">
           
              <img src='assets/img/values.png' width={50} />
              <p><strong>Awareness</strong></p>
              
              <h4 className="title">
                <a href=""> </a>
              </h4>
           
            </div>
            <div className="icon-box">
            <img src='assets/img/e.png' width={50} />
            <p><strong>Ethics </strong></p>
              <h4 className="title">
                <a href=""> </a>
              </h4>
             
            </div>
            <div className="icon-box">
            <img src='assets/img/t.png' width={50} />
            <p><strong>Transparency </strong></p>
              <h4 className="title">
                <a href=""></a>
              </h4>
             
            </div>
            
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default About