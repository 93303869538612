import About from "./components/About";
import Cal from "./components/Cal";
import Clients from "./components/Clients";
import Contact from "./components/Contact";
import Extra from "./components/Extra";
import Footer from "./components/Footer";
import Gallery from "./components/Gallery";
import Header from "./components/Header";
import Hero from "./components/Hero";
import IndexCards from "./components/IndexCards";
import MissionAndVision from "./components/MissionAndVision";
import PopupDisclaimer from "./components/PopupDisclaimer";

import Portfilio from "./components/Portfilio";
import Services from "./components/Services";
import Team from "./components/Team";
import Testimonials from "./components/Testimonials";
import TopMutualFunds from "./components/TopMutualFunds";
import TradingViewWidget from "./components/TradingViewWidget";
import Values from "./components/Values";


function App() {
  return (

<>

    <Header />
    <Hero /> 
    <IndexCards />
    <MissionAndVision />
    <Values />
    <Services />
    {/* <TradingViewWidget /> */}
    

    <About />
    <Extra />

    <TopMutualFunds />
    {/* <Testimonials /> */}
    <Cal />
    <Contact />
    <PopupDisclaimer />
    <Footer />
</>

  
  );
}

export default App;
