import React, { useState, useEffect } from 'react';
import numeral from 'numeral';

const Compcal = () => {
  const [initialInvestment, setInitialInvestment] = useState(10000);
  const [monthlyContribution, setMonthlyContribution] = useState(100);
  const [investmentLength, setInvestmentLength] = useState(5);
  const [estimatedInterestRate, setEstimatedInterestRate] = useState(5);
  const [interestRateVariance, setInterestRateVariance] = useState(1);
  const [compoundFrequency, setCompoundFrequency] = useState(1);
  const [interestEarned, setInterestEarned] = useState(0);

  useEffect(() => {
    calculateInterest();
  }, [initialInvestment, monthlyContribution, investmentLength, estimatedInterestRate, interestRateVariance, compoundFrequency]);

  const calculateInterest = () => {
    const rateMin = estimatedInterestRate - interestRateVariance;
    const rateMax = estimatedInterestRate + interestRateVariance;
    const rateAvg = (rateMin + rateMax) / 2;

    const totalContributions = monthlyContribution * 12 * investmentLength;

    let total = initialInvestment;

    for (let i = 0; i < investmentLength; i++) {
      total *= (1 + rateAvg / 100 / compoundFrequency);
      total += monthlyContribution * 12 / compoundFrequency;
    }

    setInterestEarned(total - initialInvestment - totalContributions);
  };

  return (
    <div className="bg-dark text-light min-vh-100" id="compoundInterestCalc" style={{borderRadius:'20px'}}>
      <div className="container py-4">
        <div className="text-center mb-4">
          <h1 className="font-weight-bold">Compound Interest</h1>
        </div>
        <div className="row">
          <div className="col">
            <h2 className="text-center mt-4">Interest Earned: <span style={{ color: 'green' }}>{numeral(interestEarned).format('₹0,0')}</span></h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="card bg-light">
              <div className="card-body text-center">
                <h5 className="card-title text-uppercase">Initial Investment</h5>
                <input
                  type="number"
                  className="form-control text-center font-weight-bold"
                  value={initialInvestment}
                  onChange={(e) => setInitialInvestment(parseInt(e.target.value))}
                />
                <p className="text-muted">{numeral(initialInvestment).format('₹0,0')}</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card bg-light">
              <div className="card-body text-center">
                <h5 className="card-title text-uppercase">Monthly Contribution</h5>
                <input
                  type="number"
                  className="form-control text-center font-weight-bold"
                  value={monthlyContribution}
                  onChange={(e) => setMonthlyContribution(parseInt(e.target.value))}
                />
                <p className="text-muted">{numeral(monthlyContribution).format('₹0,0')}</p>
              </div>
            </div>
          </div>
   

          <div className="col-md-4 mb-4">
            <div className="card bg-light">
              <div className="card-body text-center">
                <h5 className="card-title text-uppercase">Investment Length (Years)</h5>
                <input
                  type="number"
                  className="form-control text-center font-weight-bold"
                  value={investmentLength}
                  onChange={(e) => setInvestmentLength(parseInt(e.target.value))}
                />
                <p className="text-muted">{investmentLength} years</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card bg-light">
              <div className="card-body text-center">
                <h5 className="card-title text-uppercase">Estimated Interest Rate (%)</h5>
                <input
                  type="number"
                  className="form-control text-center font-weight-bold"
                  value={estimatedInterestRate}
                  onChange={(e) => setEstimatedInterestRate(parseInt(e.target.value))}
                />
                <p className="text-muted">{estimatedInterestRate}%</p>
              </div>
            </div>
          </div>
   
      
          <div className="col-md-4 mb-4">
            <div className="card bg-light">
              <div className="card-body text-center">
                <h5 className="card-title text-uppercase">Interest Rate Variance (%)</h5>
                <input
                  type="number"
                  className="form-control text-center font-weight-bold"
                  value={interestRateVariance}
                  onChange={(e) => setInterestRateVariance(parseInt(e.target.value))}
                />
                <p className="text-muted">{interestRateVariance}%</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
                <div className="card bg-light">
                    <div className="card-body text-center">
                    <h5 className="card-title text-uppercase">Compound Frequency</h5>
                    <div className="form-check form-check-inline">
                        <input
                        className="form-check-input"
                        type="radio"
                        id="compoundFrequencyAnnually"
                        value="1"
                        checked={compoundFrequency === 1}
                        onChange={() => setCompoundFrequency(1)}
                        />
                        <label className="form-check-label" htmlFor="compoundFrequencyAnnually">Annually</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                        className="form-check-input"
                        type="radio"
                        id="compoundFrequencyMonthly"
                        value="12"
                        checked={compoundFrequency === 12}
                        onChange={() => setCompoundFrequency(12)}
                        />
                        <label className="form-check-label" htmlFor="compoundFrequencyMonthly">Monthly</label>
                    </div>

                    </div>
                </div>
                </div>

        </div>
     
      </div>
    </div>
  );
};

export default Compcal;
