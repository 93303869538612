import React from 'react'

function Extra() {
  return (
    <div>    <section id="counts" className="counts">
    <div className="container">
      <div className="text-center title">
        <h3 className='mb-5'>Business Associates</h3>
      </div>
      <div className="row counters position-relative text-center">
      <div className='row mt-5'>
        <div className='col-lg-6 mb-5'>
          <img src='assets/img/clients/2.png' />
          </div>
          <div className='col-lg-6'>
            <img src='assets/img/clients/1.png' />
          </div>
          
        </div>
        
      </div>
    </div>
  </section></div>
  )
}

export default Extra




// import React from 'react'

// function Extra() {
//   return (
//     <div>    <section id="counts" className="counts">
//     <div className="container">
//       <div className="text-center title">
//         <h3>Plan for your Financial success!</h3>
//         <p>
//         Discover a guided, personalized approach to managing your wealth with us!
//         </p>
        
//       </div>
//       <div className="row counters position-relative">
        
//       </div>
//     </div>
//   </section></div>
//   )
// }

// export default Extra