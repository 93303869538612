import React, { useState, useEffect } from 'react';

const TopMutualFunds = () => {
  const [mutualFunds, setMutualFunds] = useState([]);

  useEffect(() => {
    fetch('https://marketapi.intoday.in/widget/bestmutualfunds/view?duration=1y&broad_category_group=Equity&fund_level_category_name=Large-Cap&page=1')
      .then(response => response.json())
      .then(data => {
        setMutualFunds(data.data.slice(0, 12)); // Extracting top 10 mutual funds
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <section className='topMutualfunds'>
    <div className="container mt-5">
      <div className="text-center top-title">
        <h3 className='mb-5'><strong>Top Mutual Funds</strong> </h3>
      </div>
      <div className="row">
        {mutualFunds.map(fund => (
          <div key={fund.isin} className="col-md-6 col-lg-4 mb-4">
            <div className="card">
              <img src={fund.image} className="image" alt={fund.legal_name} />
              {/* <h5 className="number-h1">{fund.dp_rank}</h5> */}
              <h5 className="h1">{fund.legal_name}</h5>
              {/* <p className="p">{fund.indian_risk_level}</p> */}
              <ul className="list-group list-group-flush">
                <li className="list-group-item text-center">NAV: ₹{fund.dp_day_end_nav}</li>
                <li className="list-group-item">Fund Size: ₹{fund.fna_as_Of_original_reported}</li>
              </ul>
              <div className="card-footer">
                <small className="text-muted">Fund Return: {fund.dp_return_1Yr}% <br></br> Category Return: {fund.dp_category_return_1yr}%</small>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </section>
  );
};

export default TopMutualFunds;
