import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import numeral from 'numeral';

const priceInWords = (price) => {
  // Function to convert numbers to words (price_in_words equivalent)
  // Implementation here...
};

const formatNumber = (value) => {
  return numeral(value).format('0,0.00');
};

const Anualcal = () => {
  const [principal, setPrincipal] = useState(Cookies.get('principal') || 100000);
  const [interest, setInterest] = useState(Cookies.get('interest') || 12);
  const [tenure, setTenure] = useState(Cookies.get('tenure') || 1);
  const [payment, setPayment] = useState(undefined);
  const [totalInterest, setTotalInterest] = useState(undefined);

  useEffect(() => {
    calculateAnnuity();
  }, [principal, interest, tenure]);

  const calculateAnnuity = () => {
    const monthlyInterest = interest / (12 * 100);
    const periods = tenure * 12;
    const annuityFactor = (monthlyInterest * Math.pow(1 + monthlyInterest, periods)) / (Math.pow(1 + monthlyInterest, periods) - 1);
    const annuity = principal * annuityFactor;
    const totalPayment = annuity * periods;
    const totalInterestPaid = totalPayment - principal;

    setPayment(annuity.toFixed(2));
    setTotalInterest(totalInterestPaid.toFixed(2));

    Cookies.set('principal', principal);
    Cookies.set('interest', interest);
    Cookies.set('tenure', tenure);
  };

  return (
    <div className="bg-dark text-light min-vh-100" id="annuityCalculator" style={{borderRadius:'20px'}}>
      <div className="container py-4">
        <div className="text-center mb-4">
          <h1 className="font-weight-bold">Annuity Payment</h1>
          <h2 className="text-success">
            ₹ {formatNumber(payment)} per month
          </h2>
          <p>
            for {tenure * 12} months
          </p>
        </div>
        <div className="text-center">
          <h1 className="font-weight-bold">Total Interest Paid</h1>
          <h2 className="text-success">₹ {formatNumber(totalInterest)}</h2>
        </div>
        <div className="my-4">
          <div className="card bg-light mb-4">
            <div className="card-body text-center">
              <h5 className="card-title text-uppercase">Principal Amount</h5>
              <p className="text-muted">Click to Enter</p>
              <input
                type="number"
                className="form-control text-center font-weight-bold"
                value={principal}
                onChange={(e) => setPrincipal(parseInt(e.target.value))}
              />
              <p className="text-muted">{priceInWords(principal)}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-4">
              <div className="card bg-light">
                <div className="card-body text-center">
                  <h5 className="card-title text-uppercase">Tenure</h5>
                  <input
                    type="number"
                    className="form-control text-center font-weight-bold"
                    value={tenure}
                    onChange={(e) => setTenure(parseInt(e.target.value))}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="card bg-light">
                <div className="card-body text-center">
                  <h5 className="card-title text-uppercase">Interest Rate</h5>
                  <input
                    type="number"
                    step="0.01"
                    className="form-control text-center font-weight-bold"
                    value={interest}
                    onChange={(e) => setInterest(parseFloat(e.target.value))}
                  />
                  <span className="ml-2">%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="text-center text-muted">&copy; 2024. Your Name</p>
      </div>
    </div>
  );
};

export default Anualcal;
