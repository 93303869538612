import React from 'react'

function Services() {
  return (
    <div>
             <section id="services" className="services section-bg">
        <div className="container">
          <div className="section-title">
            <h2>Services</h2>
            <p></p>
          </div>
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-4 col-md-6">
              <div className="icon-box d-flex flex-column">
                <div className="icon d-flex justify-content-center align-items-center">
                  <img src='assets/img/10.png' width={100} />
                </div>
                <div>
                  <h4 className="title d-flex justify-content-center align-items-center">
                    <a href="">Corporate Finnace</a>
                  </h4>
                  <p className="description text-center">
                  Smooth sailing through financial challenges
                  <br></br>
                  <br></br>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="icon-box d-flex flex-column">
                <div className="icon d-flex justify-content-center align-items-center">
                  <img src='assets/img/8.png' width={100} />
                </div>
                <div>
                  <h4 className="title d-flex justify-content-center align-items-center">
                    <a href="">Investment Advisory </a>
                  </h4>
                  <p className="description text-center">
                  At WealthHunters, find personalized investment advice to maximize your financial goals
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-wow-delay="0.1s">
              <div className="icon-box  d-flex flex-column">
                <div className="icon d-flex justify-content-center align-items-center">
                  <img src='assets/img/7.png' width={100} />
                </div>
                <div>
                  <h4 className="title d-flex justify-content-center align-items-center">
                    <a href="">Exim Services </a>
                  </h4>
                  <p className="description text-center">
                  We excel in Exim services, simplifying your global trade with comprehensive assistance.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-wow-delay="0.2s">
              <div className="icon-box d-flex flex-column">
                <div className="icon d-flex justify-content-center align-items-center">
                  <img src='assets/img/13.png' width={100} />
                </div>
                <div>
                  <h4 className="title d-flex justify-content-center align-items-center">
                    <a href="">Financial Planning </a>
                  </h4>
                  <p className="description text-center">
                  Ensure your financial future with our personalized planning for education, major purchases, and long-term wealth.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-wow-delay="0.2s">
              <div className="icon-box d-flex flex-column">
                <div className="icon d-flex justify-content-center align-items-center">
                  <img src='assets/img/12.png' width={100} />
                </div>
                <div>
                  <h4 className="title d-flex justify-content-center align-items-center">
                    <a href="">Retirement Planning  </a>
                  </h4>
                  <p className="description text-center">
                  Plan your retirement with confidence through our specialized services for optimizing savings and income.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-wow-delay="0.2s">
              <div className="icon-box d-flex flex-column">
                <div className="icon d-flex justify-content-center align-items-center">
                  <img src='assets/img/11.png' width={100} />
                </div>
                <div>
                  <h4 className="title d-flex justify-content-center align-items-center">
                    <a href="">Liaison Service </a>
                  </h4>
                  <p className="description text-center">
                  Simplify business operations with our liaison services, facilitating smooth communication and issue resolution
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-wow-delay="0.2s">
              <div className="icon-box d-flex flex-column">
                <div className="icon d-flex justify-content-center align-items-center">
                  <img src='assets/img/9.png' width={100} />
                </div>
                <div>
                  <h4 className="title d-flex justify-content-center align-items-center">
                    <a href="">Wealth Management </a>
                  </h4>
                  <p className="description text-center">
                  Trust WealthHunters for tailored wealth management, ensuring lasting financial success.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-wow-delay="0.2s">
              <div className="icon-box d-flex flex-column">
                <div className="icon d-flex justify-content-center align-items-center">
                  <img src='assets/img/5.png' width={100} />
                </div>
                <div>
                  <h4 className="title d-flex justify-content-center align-items-center">
                    <a href="">Project Counselling </a>
                  </h4>
                  <p className="description text-center">
                  Navigate projects confidently with expert counselling from WealthHunters.
                  </p>
                </div>
              </div>
            </div>
            

          </div>
        </div>
      </section>
    </div>
  )
}

export default Services