import React, { useState } from 'react';


function PopupDisclaimer(props) {
  // State to manage the visibility of the popup
  const [showPopup, setShowPopup] = useState(true);

  // Function to handle the "Accept" button click
  const handleAccept = () => {
    // Hide the popup when the user clicks "Accept"
    setShowPopup(false);
  };

  // Function to handle the "Decline" button click
  const handleDecline = () => {
    // Hide the popup when the user clicks "Decline"
    setShowPopup(false);
    // More importantly close the window (other behavior can be considered)
    window.close();
  };

  return (
    // Show the popup only when showPopup is true
    showPopup && (
      <div className="popup-container">
        <div className="popup">
          <h1>Disclaimer</h1>
          <p id="pdisclaimer">This website contains demonstration content for illustrative purposes only. None of the information provided herein should be considered as financial, legal, or professional advice. Any simulated data, charts, or examples are purely fictional and do not reflect real-world investment opportunities or outcomes. While efforts have been made to ensure the accuracy and reliability of the demonstration content, no guarantees are made regarding its completeness or suitability for any specific purpose. Users are encouraged to exercise caution and seek professional advice before making any financial decisions based on the information provided on this website. We disclaim all liability for any loss or damage resulting from reliance on the demo content or its interpretation.</p>
          <div className="buttons">
            <button className="accept" onClick={handleAccept}>Okay</button>
            {/* <button className="decline" onClick={handleDecline}>Decline</button> */}
          </div>
        </div>
      </div>
    )
  );
}

export default PopupDisclaimer;