import React from 'react'

function Values() {
  return (
    <div>
          <section id="counts" className="counts">
    <div className="container">
      <div className="text-center title">
        <h3 className='mb-5'>Values</h3>
      </div>
      <div className="row counters position-relative text-center">
      <div className='row mt-5'>
        <div className='col-lg-3  mb-5'>
          <img src='assets/img/values.png'  width={100}  alt='values'/>
          <p><strong>Awareness</strong></p>
          </div>
          <div className='col-lg-3  mb-5'>
          <img src='assets/img/e.png'  width={100}  alt='Ethics'/>
          <p><strong>Ethics</strong></p>
          </div>
          <div className='col-lg-3 mb-5'>
          <img src='assets/img/t.png'  width={100} alt='Transparency' />
          <p><strong>Transparency</strong></p>
          </div>
          <div className='col-lg-3 mb-5'>
          <img src='assets/img/s.png'  width={100} alt='Protection' />
          <p><strong>Protection</strong></p>
          </div>
          
        </div>
        
      </div>
    </div>
  </section>
    </div>
  )
}

export default Values