import React from 'react'
import EMIcal from './EMIcal';
import Compcal from './Compcal';
import Anualcal from './Anualcal';

function Cal() {
  return (
    <div>
        <section id="tabs" class="tabs">
                <div class="container" data-aos="fade-up">

                <div class="section-title">
                    <h2>calculator</h2>
                    <p></p>
                </div>

                <ul class="nav nav-tabs row d-flex justify-content-center">
                    <li class="nav-item col-3">
                    <a class="nav-link active" data-bs-toggle="tab" data-bs-target="#tab-1" style={{borderRadius:'5px'}}>
                    <img src='assets/img/EMI.png' alt='' width={50} className='p-2' />
                        <h4 class="d-none d-lg-block cstm-h4">Emi calculator</h4>
                    </a>
                    </li>
                    <li class="nav-item col-3">
                    <a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2" style={{borderRadius:'5px'}}>
                    <img src='assets/img/com.png' alt='' width={50} className='p-2' />
                        <h4 class="d-none d-lg-block cstm-h4">Compound calculator</h4>
                    </a>
                    </li>
                    <li class="nav-item col-3">
                    <a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-3" style={{borderRadius:'5px'}}>
                    <img src='assets/img/anual.png' alt='' width={50} className='p-2' />
                        <h4 class="d-none d-lg-block cstm-h4">Annuity calculator</h4>
                    </a>
                    </li>
             
                </ul>

                <div class="tab-content">
                    <div class="tab-pane active show" id="tab-1">
                    <div class="row">
                   
                      <EMIcal />

                    </div>
                    </div>
                    <div class="tab-pane" id="tab-2">
                    <div class="row">

                    <Compcal />
   
                    </div>
                    </div>
                    <div class="tab-pane" id="tab-3">
                    <div class="row">

                        <Anualcal />
                      
                    </div>
                  
                    </div>
             
                </div>

                </div>
            </section>
    </div>
  )
}

export default Cal;