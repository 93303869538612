import React from 'react';

function Footer() {
  return (
    <footer className="footer-section">
      <div className="container">
        <div className="footer-cta pt-5 pb-5">
          <div className="row">
            <div className="col-xl-4 col-md-4 mb-30">
              <div className="single-cta">
                <i className="bi bi-geo-alt"></i>
                <div className="cta-text">
                  <h4>Find us</h4>
                  <span>Visakhapatnam</span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 mb-30">
              <div className="single-cta">
                <i className="bi bi-phone"></i>
                <div className="cta-text">
                  <h4>Call us</h4>
                  <span>+91 8309767826</span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 mb-30">
              <div className="single-cta">
                <i className="bi bi-envelope-open"></i>
                <div className="cta-text">
                  <h4>Mail us</h4>
                  <span>info@wealthhunters.comm</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-content pt-5 pb-5">
          <div className="row">
            <div className="col-xl-4 col-lg-4 mb-50">
              <div className="footer-widget">
                <div className="footer-logo">
                  <a href="index.html"><img src="assets/img/logo.jpg" className="img-fluid" alt="logo" style={{borderRadius:"5px"}} /></a>
                </div>
                <div className="footer-text">
                  <p>Our journey began in Visakhapatnam, the City of Destiny, in Andhra Pradesh as a wealth management company. We are a value driven company and our core values are based on four simple principles awareness, trust, respect and service</p>
                </div>
                
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Useful Links</h3>
                </div>
                <ul>
                  <li><a href="#hero">Home</a></li>
                  <li><a href="#about">About us</a></li>
                  <li><a href="#services">Services</a></li>
  
                  <li><a href="#contact">Contact us</a></li>
            
        
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Follow us</h3>
                </div>
                
                <div className="footer-social-icon">
                  <span></span>
                  <a href="#"><i className="bi bi-facebook"></i></a>
                  <a href="#"><i className="bi bi-twitter"></i></a>
                  <a href="#"><i className="bi bi-google"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="row">
          
          
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
